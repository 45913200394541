import $ from "jquery";

function ornamentPlacement(element, bottomOrnament) {
  let distanceFromTop;
  let isMobile = window.matchMedia("(max-width: 414px)").matches;
  let isTablet = window.matchMedia("(min-width:415px) and (max-width: 768px)").matches;
  const blockYpos = element.offset().top;

  
  let ornamentParams = {
    position: "absolute",
    width: "30vw",
    right: "6vw",
    opacity: "0",
    zIndex: 1,
  };


  if (isMobile) {
    distanceFromTop = blockYpos - 30;
  } else if (isTablet) {
    ornamentParams.right = "7vw";
    ornamentParams.width = "20vw";

    distanceFromTop = blockYpos - 40;
  }
 else {
  //desktop
  distanceFromTop = blockYpos - 40
  ornamentParams.width = "25vw";
  ornamentParams.right = "7vw";
}
  ornamentParams.opacity = "1";
  ornamentParams.top = distanceFromTop + "px",


  bottomOrnament.css(ornamentParams);
}

function updateOrnamentPosition() {
  $(".mg-block--testimonial").each(function () {
    const element = $(this);
    const bottomOrnament = element.prev(".testimonial-svg");
    if (bottomOrnament.length) {
      ornamentPlacement(element, bottomOrnament);
    }
  });
}

$(document).ready(function () {
  $(".mg-block--testimonial").each(function () {
    const element = $(this);
    const ornament = element.find(".testimonial-svg");
    if (ornament.length) {
      ornamentPlacement(element, ornament);
      ornament.insertBefore(element);
    }
  });

  $(window).on("resize", updateOrnamentPosition);
});
